import Vue from 'vue/dist/vue.min';
import {faqlist} from './components/faqlist';

export function faq(controller , scene){
    const v = new Vue({
        el: '#faq',
        components: {
            list: faqlist()
        }
    });
}