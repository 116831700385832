import Swiper from 'swiper/swiper-bundle.min';
import ScrollMagic from 'scrollmagic';
import {TweenMax} from "gsap";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax);
TweenMax.defaultOverwrite = false;

export function index(controller , scene){
    const slice_text = document.getElementsByClassName('txt_slice');
    for(let i = 0; i < slice_text.length; i++){
        slicer(slice_text[i]);
    }
    function slicer(elem){
        const txtchange =  elem.innerHTML.replace(/<br>/g, '_');
        const txt =  txtchange;
        elem.innerHTML = '';
        txt.split('').forEach(function(c) {
            if(c == '_'){
                elem.innerHTML += '<br>';
            }
            else if(c == ' '){
                elem.innerHTML += '<span>&nbsp;</span>';
            }
            else{
                elem.innerHTML += '<span>'+c+'</span>';
            }
        });
    }

	const serv = document.getElementById('servswipe');
	if(serv){
		var swiper = new Swiper('#servswipe', {
			slidesPerView: 'auto',
			spaceBetween: 40,
			speed: 600,
			loop: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
		});
	}
	
	const fv = document.getElementById('firstview');
	if(fv){
		setTimeout(function(){
			fv.classList.add('active');
			fv.style.maxHeight = window.innerHeight + 'px';
		},80);
	}
	
	const news = document.getElementById('newswipe');
	if(news){
		var swiper = new Swiper('#newswipe', {
			slidesPerView: '1',
			spaceBetween: 60,
			speed: 600,
			loop: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
			},
			navigation: {
                nextEl: ".moreside",
        	},
		});
	}
	
    if(document.getElementById('movetitle')){
        scene.push(
            new ScrollMagic.Scene({tweenChanges: false,triggerElement: "#movetitle", duration: '300%', offset: '-800'})
                .setTween("#movetitle", { left: '32%' })
                .addTo(controller)
        );
    }


}