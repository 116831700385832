import ScrollMagic from 'scrollmagic';
import Barba from 'barba.js';
import {butter} from './lib/butter';
import {scrolEvents} from './default';
import {loading} from './loading';
import {index} from './index';
import {common} from './common';
import {faq} from './faq';
import {vm} from './vm';

document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
    const scrollOpt = {
        wrapperId: 'scrollelem',
        wrapperDamper: 0.08,
        cancelOnTouch: false
    }
	let scrollAll = butter();
	
    vm();
	init(controller , scene);
	pageScroll(scrollAll, scrollOpt);
	
	function init(controller, scene){
		scrolEvents(controller , scene);
		common(controller , scene);
		faq(controller , scene);
	}
	function pageScroll(scrollAll, scrollOpt){
		if (!(navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0 || navigator.userAgent.indexOf('iPad') > 0 || navigator.userAgent.indexOf('Android') > 0)) {
			scrollAll.init(scrollOpt);
		}
	}
	
    // LOADING 処理
    const keyName = 'visited';
    const keyValue = true;
    if (!sessionStorage.getItem(keyName)) {
        sessionStorage.setItem(keyName, keyValue);
        loading(controller , scene);
    } else {
        const intro = document.getElementById('intro');
        if(intro){
            intro.remove();
        }
        index(controller , scene);
    }
	
	//　USE BRABA PJAX
    Barba.Pjax.start();
    Barba.Prefetch.init();
    var move_elem1 = document.querySelector('._move');
	var head = document.getElementById('site-header');

	var time = 2800;
    var ShutterAnimation = Barba.BaseTransition.extend({
		
		start: function() {
			Promise
				.all([this.newContainerLoading, this.shutter(time)])	
				.then(this.finish.bind(this));
		},
		

		shutter: function(timer) {
			document.body.classList.remove('fix');
			move_elem1.classList.add('moved');
			head.classList.add('mix');

			setTimeout(function(){
				document.documentElement.scrollTop = 0;
				document.body.scrollTop = 0;
			},timer/2);

			setTimeout(function(){
				head.classList.remove('mix');
			},timer*.78);

			setTimeout(function () {
				move_elem1.classList.remove('moved');
			},timer);
		},

		finish: function() {
			this.done();
			scene = [];
            scrollAll = null;
            scrollAll = butter();
			setTimeout(function(){
				init(controller, scene);
				index();
			},400);
			setTimeout(function(){
				pageScroll(scrollAll, scrollOpt);
			},800);
		}
    });

    Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, container, newPageRawHTML) {
		const head = document.head;
		const newPageRawHead = newPageRawHTML.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [
			"meta[name='keywords']",
			"meta[name='description']",
			"meta[property^='og']",
			"meta[name^='twitter']",
			"meta[itemprop]",
			"link[itemprop]",
			"link[rel='prev']",
			"link[rel='next']",
			"link[rel='canonical']"
			].join(',');
		const headTags = head.querySelectorAll(removeHeadTags);
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags);
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
    });

    Barba.Pjax.getTransition = function() {
    	return ShutterAnimation;
    };

});