export function faqlist(){
    const temp = {
        data: function(){
            return {
                flag: false
            }
        },
        template: 
        `
            <dl @click="flag = !flag" :class="{'active': flag}">
				<dt v-html="title" :class="{'active': flag}"></dt>
				<dd v-if="flag"><slot></slot></dd>
			</dl>
        `,
		props: ['title']
    }
    return temp;
};